import React from 'react';
import "./policyPage.css"
import TitleBar from '../../components/titleBar/TitleBar';

const ClarificationText = () => {
    return (
        <div className='policy-page-container'>



            <div className="text-center bg-gray-100 p-2 mb-4 shadow-md border-b-[5px] border-red-400">
                <h1 className="text-2xl font-bold " >Aydınlatma Metni</h1>
            </div>

            <div class="policy-page-header">
                ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.
            </div>


            <div class="policy-page-section">
                <h2>Aydınlatma Metni ve Veri Korumaya İlişkin Açıklamalar</h2>
                <p>6698 Sayılı Kişisel Verilerin Korunması Kanunu’na (Bundan sonra “KVKK” veya “Kanun” olarak anılacaktır.) uygun olarak kişisel verilerin ve/veya özel nitelikli kişisel verilerin işlenmesi ve muhafaza edilmesi önem arz etmektedir. Bu nedenle ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş. (Bundan sonra “ERİŞİM MEDYA” veya “Şirket” olarak anılacaktır.) olarak sizlere ait kişisel verilerin ve/veya özel nitelikli kişisel verilerin korunması, muhafaza edilmesi ve işlenmesi hususunda KVKK’ya uygun hareket ediyoruz.</p>
                <p>Sizlerden internet ortamında sıklıkla veri almaktayız. İnternet sitemizin ziyaret edilmesi esnasında alınan ve kaydedilen kişisel veriler ve/veya özel nitelikli kişisel veriler Kişisel Verilerin Korunması Kanuna uygun olarak işlenmektedir. Bu bağlamda Erişim Medya veri sorumlusu sıfatıyla kanuni sınırlar dahilinde verilerinizi almakta ve muhafaza etmektedir. Ayrıca belirtmek isteriz ki; internet sitemizin ziyareti esnasında alınan tüm kişisel veriler ve/veya özel nitelikli kişisel veriler Gizlilik Politikamız çerçevesinde de korunmaktadır. Bu konuda sayfamızda bulabileceğiniz <a className='policy-page-link' href="/gizlilik-politikasi">Gizlilik Politikamızı</a> inceleyebilirsiniz.</p>
                <p>İşbu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması Kanununun 10. maddesi ile Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında veri sorumlusu sıfatıyla Erişim Medya tarafından hazırlanmıştır.</p>
            </div>

            <div class="policy-page-section">
                <h2>1. Kişisel Veri Nedir?</h2>
                <p>Kişisel veri, 6698 Sayılı Kişisel Verilerin Korunması Kanununda “Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi” şeklinde ifade olunmuştur. Tanımdan da anlaşılacağı üzere sizleri belirlenebilir kılan her türlü bilgi kişisel veridir. Tüm bunlara ek siyasi düşünce, mezhep, ırk, din, dernek-vakıf üyeliği, sağlık bilgisi, felsefi düşünce, inanç, cinsel tercih, sabıka kaydı, ceza mahkûmiyet bilgileri, biyometrik veriler ise özel nitelikli kişisel verilerdir.</p>
            </div>

            <div class="policy-page-section">
                <h2>2. Hukuki Dayanak</h2>
                <p>6698 Sayılı Kişisel Verilen Korunması Kanunu, veri işleme faaliyetinde bulunulmadan önce verisi işlenen kişilerin aydınlatılmasını öngörmüştür. Aydınlatma Yükümlülüğü olarak addedilen bu husus Kişisel Verilerin Korunması Kanunun 10. Maddesinde “Kişisel verilerin elde edilmesi sırasında veri sorumlusu ve yetkilendirdiği kişi, ilgili kişilere; Veri sorumlusunun ve varsa temsilcisinin kimliği, Kişisel verilerin hangi amaçla işleneceği, İşlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği, Kişisel veri toplamanın yöntemi ve hukuki sebebi, 11 inci maddede sayılan diğer hakları, konusunda bilgi vermekle yükümlüdür.” şeklinde hüküm altına alınmıştır.</p>

                <p>İşbu Kanunun 3. Maddesinde veri sorumlusu da “Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiler” olarak tanımlanmıştır. Yine veri işleyen “Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi.” şeklinde hükmolunmuştur. Bu bağlamda veri sorumlusu, internet sayfasını ziyaret ettiğiniz Erişim Medya’dır. Veri sorumlusunun bilgileri de aşağıdaki gibidir.</p>

                <p><span class="policy-page-definition"> Veri Sorumlusu:</span> ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.  </p>
                <p><span class="policy-page-definition"> Adres:</span> Osmanağa Mahallesi Hasırcıbaşı Caddesi No:15 İç Kapı No:3
                    Kadıköy / İstanbul
                </p>
                <p><span class="policy-page-definition"> Telefon:</span> 0 216 550 10 61 / 62 </p>
                <p><span class="policy-page-definition"> E-posta:</span> bbekar@akilliyasamdergisi.com  </p>
            </div>

            <div class="policy-page-section">
                <h2>3. Veri Sorumlusu Erişim Medya’nın Aydınlatma Yükümlülüğünün Kapsamı</h2>
                <p>Yukarıda izah olunanlar uyarınca veri sorumlusu Erişim Medya’dır. Veri sorumlusu sıfatı altında Erişim Medya işbu Aydınlatma Metninde, kişisel verilerin kim tarafından hangi amaçla işlenebileceği, kimlere ne amaçla aktarılabileceği, verilerin toplanma yöntemi ve hukuki sebebi ile kişisel verilerin korunmasına yönelik veri sahibinin sahip olduğu haklar hususunda sizleri bilgilendirmekte ve aydınlatmaktadır.</p>
            </div>

            <div class="policy-page-section">
                <h2>4. Verilerinizin Erişim Medya Tarafından İşlenme Amaçları</h2>

                <p>Kişisel verileriniz 6698 Sayılı Kişisel Verilerin Korunması Kanunun öngördüğü sınırlar içerisinde ve kanunun ilkeleri esas alınarak işlenmektedir. Kanun çerçevesinde Erişim Medya olarak veri işleme amaçlarımız;</p>

                <ul>
                    <li>Sizlere sunulan ürün ve hizmet kalitesinin arttırılabilmesi,</li>
                    <li>Erişim Medya bünyesindeki yeniliklerden, sunulan ürün ve hizmetlerden sizlerin hızlıca haberdar edilebilmesi,</li>
                    <li>Kişiye özel ihtiyaçlar ve kullanım amaçlarının tespit edilmesi bu bağlamda siz müşterilerimize özel mal ve hizmet sunumunun gerçekleştirilebilmesi,</li>
                    <li>Erişim Medya’nın ticari faaliyetin yürütülebilmesi ve bu bağlamda sizlere eksiksiz ifanın sağlanabilmesi,</li>
                    <li>Değişen, gelişen ürün ve hizmetlerimiz hakkında sizleri bilgilendirmek ve gereken durumlarda aydınlatmanın yapılabilmesi,</li>
                    <li>Şirketimiz tarafından sunulan ürün ve hizmetlerden sizlerin en iyi şekilde faydalanabilmesi,</li>
                    <li>Erişim Medya olarak ticari ortaklıklarımızın ve stratejilerimizin geliştirilebilmesi, belirlenebilmesi, güvenli bir temele oturtulabilmesi, ticari politikalarımızda ve yönetimsel işleyişimizde doğru kararların alınabilmesi,</li>
                    <li>Erişim Medya olarak kurumsal kimliğimizin sağlam temele oturtulabilmesi ve kurumsal işleyişin sağlanabilmesi,</li>
                    <li>Şirket içi politikalarımızın hedeflerine ulaştırılması ve böylelikle siz müşterilerimizin memnuniyetinin arttırılabilmesi,</li>
                    <li>Bilgi güvenliğinin sağlanabilmesi,</li>
                    <li>Erişim Medya olarak internet üzerinden sunulan mal ve hizmetlerin geliştirilebilmesi,</li>
                    <li>Yaşanan olumsuzlukların hızla çözümlenebilmesi,</li>
                    <li>Erişim Medya’ya talep ve şikayet iletenler ile iletişime geçilebilmesi,</li>
                    <li>İnternet sitemizde yer alan <a className='policy-page-link' href="/gizlilik-politikasi">Gizlilik Politikası</a>'na ilişkin hükümlere uygunluğun sağlanabilmesi,</li>
                </ul>
                <p>Şeklindedir.</p>

            </div>

            <div class="policy-page-section">
                <h2>5. Verilerinizin İşlenmesine Yönelik İlkeler</h2>

                <ul>
                    <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilmesini,</li>
                    <li>Hukuka uygun olarak işlemeyi,</li>
                    <li>Verilerin güncelliğini sağlamayı,</li>
                    <li>Dürüstlük kurallarına uygun davranmayı,</li>
                    <li>İşlendiği amaç dışında kullanılmamasını,</li>
                    <li>İşlendiği amaçla ölçülü ve sınırlı olmasını,</li>
                    <li>Genel ahlaka, örfe ve adete uygun olarak işlemeyi,</li>
                    <li>6698 Sayılı Kişisel Verilerin Korunması Kanunu hükümleri başta olmak üzere ilgili tüm mevzuat hükümlerine uygun olarak işlemeyi,</li>
                </ul>
                <p><b>Erişim Medya</b> olarak ilke edindik. </p>


            </div>

            <div class="policy-page-section">
                <h2>6. Verilerin İşlenme Usulü</h2>

                <p>Kişisel verileriniz erişim medya tarafından iki durumun varlığı halinde işlenebilmektedir. Bu iki hal aşağıdaki gibidir:</p>

                <ul>
                    <li>Açık Rıza Kanununa Uygunluk Halleri</li>
                    <li>Açık Rızaya Gerek Olmayan Haller</li>
                </ul>

                <p className='pt-3'><span class="policy-page-definition"> Açık Rızaya Gerek Olmayan Haller</span> <br /> 6698 Sayılı Kişisel Verilerin Korunması Kanunu kişisel verinin işlenmesi için bazı hallerde açık rızaya gerek olmadığını belirtmiştir. Kanunun 5. maddesinin 2. fıkrasında belirtilen bu haller aşağıdaki gibidir:
                    <ul>
                        <li>Kanunlarda açıkça öngörülmesi,</li>
                        <li>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması,</li>
                        <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,</li>
                        <li>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</li>
                        <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması,</li>
                        <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</li>
                        <li>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>
                    </ul>
                    <p>Söz konusu durumların varlığı halinde açık rızanız olmaksızın Erişim Medya olarak veri işleyebilme hakkımız haizdir.</p>
                    <p>Özel nitelikli kişisel verilerinizden sağlık ve cinsel hayat dışındaki kişisel verileri, kanunlarda öngörülen hâllerde açık rızası aranmaksızın işleyebilme hakkımız haizdir. Sağlık ve cinsel hayata ilişkin kişisel veriler ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası aranmaksızın işlenebilir.</p>
                </p>

            </div>

            <div class="policy-page-section">
                <h2>7. Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi</h2>

                <p>Gelişen ve değişen teknolojik koşullarda farklı mecralardan şirketimizce veri toplanmaktadır. İnternet sitesi de otomatik yollarla kişisel veri topladığımız alanlardan birisidir.</p>

                <p>Erişim Medya bünyesinde internet sitesi üzerinden toplanan kişisel veriler 6698 Sayılı Kanun’un 5. maddesindeki hukuki sebeplerden birine ya da birkaçına veyahut açık rıza şartına dayanılarak işlenmektedir. İşlenen verinin kategorisine ve türüne göre ilgili kişiye aydınlatma yapıldığını, söz konusu aydınlatma metinlerinde verilerin hangi amaçla işleneceği, işlenen verilerin kimlere ve hangi amaçla aktarılabileceği, veri toplamanın yöntemi ve hukuki sebebi ve ilgili kişinin sahip olduğu haklar uyarınca açıklama yapıldığını ve gerektiği durumda açık rıza alındığını belirtmek isteriz.</p>

            </div>

            <div class="policy-page-section">
                <h2>8. Verilerin Aktarılması ve Korunması</h2>

                <p className='pt-3'><span class="policy-page-definition">a- Aktarılması</span> <br /> Verileriniz, 6698 Sayılı Kişisel Verilerin Korunması Kanuna uygun olarak, gizlilik sözleşmelerimizdeki koşullar uyarınca gerekli olduğu durumlarda üçüncü kişilere aktarılmaktadır. Aktarılan her veri için veri elde edilmeden önce yapılan Aydınlatma Metninde verinin hangi amaçla kimlere aktarılabileceği hususunda ayrıntılı açıklama yapılmaktadır. Fakat her koşulda Erişim Medya veri aktarımı yaparken 6698 Sayılı Kanun 8. ve 9. Maddelerine uygun hareket etmektedir. Ek olarak gerekli teknik ve idari tedbirlerde almaktadır.
                </p>
                <p className='pt-3'><span class="policy-page-definition">b- Korunması</span> <br /> 6698 Sayılı Kişisel Verilerin Korunması Kanunu asıl olarak verilerin korunmasını ve izinsiz aktarımın önüne geçilmesini amaçlamaktadır. Başta ilgili kanun hükümleri olmak üzere verilerin yetkisiz üçüncü kişilere aktarımının önüne geçebilmek için Erişim Medya olarak gereken teknik ve idari tedbiri almaktayız. Bu bağlamda bilhassa şirket içi politikalarımızı Kişisel Verilerin Korunması Kanunu’na uygun hale getirmekte ve kişisel verilerin korunmasına yönelik her türlü teknik yazılım ve donanımı bünyemizde bulundurmaktayız. Tüm bunları sağlayabilmek adına gerek şirket içinde çalışanlarımızın gerekse iş yaptığımız üçüncü kişilerin <a className='policy-page-link' href="/gizlilik-politikasi">Gizlilik Politikamıza</a> aykırı davranmasını önlemeye çalışmaktayız.
                </p>


            </div>

            <div class="policy-page-section">
                <h2>9. Veri Sahibi İlgili Kişinin Hakları</h2>

                <p className='pt-3'><span class="policy-page-definition"> a- Başvuru Hakkında Genel Bilgilendirme</span> <br /> 6698 Sayılı Kişisel Verilerin Korunması Kanunu 11. Maddesinde veri sahibinin haklarını hüküm altına almıştır. Kanunda veri sahibi “ilgili kişi” olarak addedilmiş olup; verilerinin işlenmesine ilişkin bazı taleplerde bulunma hakkı öngörülmüştür.  İşbu madde uyarınca ilgili kişinin talep hakları aşağıdaki gibidir:
                    <ul>
                        <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                        <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                        <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                        <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                        <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                        <li>“Kişisel verilerin silinmesi, yok edilmesi veya anonim hâle getirilmesi” başlıklı Kanunun 7. Maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                        <li>Eksik ya da yanlış işlenmiş verinin düzeltildiğini veya ilgilinin talebi üzerine verinin silindiğini veyahut yok edildiğine ilişkin bilginin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                        <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                        <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                    </ul>
                    <p>Yukarıda izah olunan Kişisel Verilerin Korunması Kanunundan doğan haklarınıza ilişkin taleplerinizi, işbu Aydınlatma Metninde belirtilen usul çerçevesinde şirketimize iletebilirsiniz. Söz konusu talepleriniz Erişim Medya tarafından mümkün olan en kısa sürede veya en geç otuz gün içerisinde cevaplanacaktır. Başvurular bizzat veri sahibinin kendisi tarafından yapılmak zorundadır. Erişim Medya sadece veri sahibi tarafından yapılan başvuruyu dikkate alacak ve başvuru yapan hakkında bilgiler paylaşacaktır.</p>
                </p>

                <p className='pt-3'><span class="policy-page-definition"> b- Başvuru Usulü                </span> <br /> Veri sorumlusu sıfatı taşıyan Erişim Medya’ya yukarıda belirtilen haklarınıza yönelik taleplerinizi yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da ilgili kişi tarafından veri sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle iletebilirsiniz. Kişisel verilerle ilgili talep haklarınızı kullanabilmek için internet sitemizdeki bilgilendirmeleri inceleyebilir ve yine internet sitemizde bulunan “6698 Sayılı Kişisel Verilerin Korunması Kanunu Uyarınca İletişim ’i kullanabilirsiniz.
                </p>

                <p className='pt-3'><span class="policy-page-definition"> c- Başvuruda Yer Alması Gereken Bilgiler </span> <br /> Başvurunuzda yer alması gereken bilgiler aşağıdaki gibidir:
                    <ul>
                        <li>Adınız ve Soyadınız,</li>
                        <li>T.C. Kimlik Numaranız,</li>
                        <li>Yabancı Uyruklu İseniz Uyruğunuz, Pasaport No veya Varsa Kimlik Numaranız,</li>
                        <li>Yazılı Başvuru Yapılacak İse Talep Formunun Altında Islak İmzanız,</li>
                        <li>KVKK 11. Madde uyarınca Talebinizin İçeriği,</li>
                        <li>Size Ulaşım Sağlayabileceğimiz Telefon ve Faks Numarası/Numaraları,</li>
                        <li>Size Ulaşım Sağlayabileceğimiz E-Posta Adresiniz,</li>
                        <li>Tebligata Esas Yerleşim Yeri veya İş Yeri Adresi.</li>
                    </ul>

                </p>



            </div>

            <div class="policy-page-section">
                <h2>Şirketimizin Bilgileri Aşağıdaki Gibidir:</h2>

                <p><span class="policy-page-definition"> Veri Sorumlusu:</span> ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.  </p>
                <p><span class="policy-page-definition"> Adres:</span> Osmanağa Mahallesi Hasırcıbaşı Caddesi No:15 İç Kapı No:3 Kadıköy / İstanbul
                </p>
                <p><span class="policy-page-definition"> Telefon:</span> 0 216 550 10 61 / 62 </p>
                <p><span class="policy-page-definition"> E-posta:</span> bbekar@akilliyasamdergisi.com  </p>

            </div>
            
            <br />
            <span class="policy-page-definition ">ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK A.Ş.</span>



        </div>
    )
}

export default ClarificationText