import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

// Utility function to capitalize the first letter and make the rest lowercase
const formatCategoryName = (name) => {
  return name
    .split(' ')
    .map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ');
};

// Updated utility function to format category name for URL
const formatCategoryNameForURL = (name) => {
  const turkishChars = { 'ğ': 'g', 'ü': 'u', 'ş': 's', 'ı': 'i', 'ö': 'o', 'ç': 'c',
                         'Ğ': 'G', 'Ü': 'U', 'Ş': 'S', 'İ': 'I', 'Ö': 'O', 'Ç': 'C' };
  return name
    .toLowerCase()
    .replace(/[ğüşıöçĞÜŞİÖÇ]/g, char => turkishChars[char] || char)
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9\-]/g, ''); // Remove any other non-alphanumeric characters except hyphens
};

// Scroll to the top of the page
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const CategoryLinks = () => {
  const [categories, setCategories] = useState([]);
  const location = useLocation();

  useEffect(() => {
    axios
      .get("https://api.ekonomimanset.com/api/categories")
      .then((response) => {
        const visibleCategories = response.data.filter(
          (category) => category.isVisible
        );
        setCategories(visibleCategories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  return (
    <div>
      {/* Category Links */}
      <div className="flex flex-wrap gap-4 mt-4">
        {categories.map((category, index) => (
          <Link
            key={category._id}
            to={`/${formatCategoryNameForURL(category.name)}`}
            onClick={scrollToTop}
            className={`p-2.5 text-base transition-all rounded-lg hover:scale-110 ${
              index % 6 === 0
                ? "bg-[#57c4ff31]"
                : index % 6 === 1
                ? "bg-[#da85c731]"
                : index % 6 === 2
                ? "bg-[#7fb88133]"
                : index % 6 === 3
                ? "bg-[#ff795736]"
                : index % 6 === 4
                ? "bg-[#ffb04f45]"
                : "bg-[#5e4fff31]"
            }`}
          >
            {formatCategoryName(category.name)}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryLinks;