import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import "./Carousel.css";
import LiveStream from "../liveStream/LiveStream";

const API_BASE_URL = "https://api.ekonomimanset.com";

const HpBannerBox = () => {
  const [slidesData, setSlidesData] = useState([]);
  const [bannerRightData, setBannerRightData] = useState([]);
  const [downLinksData, setDownLinksData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [direction, setDirection] = useState('forward');

  const nextSlide = useCallback(() => {
    setDirection('forward');
    setCurrentSlide((prevSlide) => (prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1));
  }, [slidesData.length]);

  const prevSlide = useCallback(() => {
    setDirection('backward');
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slidesData.length - 1 : prevSlide - 1));
  }, [slidesData.length]);


  const handleSlideClick = useCallback(async (newsId, slug) => {
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      window.location.href = `/${slug}`;
    } catch (error) {
      console.error("Error incrementing view count:", error);
      window.location.href = `/${slug}`;
    }
  }, []);

  const handleNewsClick = useCallback(async (newsId, slug) => {
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      window.location.href = `/${slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      window.location.href = `/${slug}`;
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const [
        slidesResponse,
        bannerRightResponse,
        downLinksResponse,
        thirdResponse,
        pinnedNewsResponse,
      ] = await Promise.all([
        axios.get(`${API_BASE_URL}/full-width-headlines`),
        axios.get(`${API_BASE_URL}/side-headline-components`),
        axios.get(`${API_BASE_URL}/under-headline-components`),
        axios.get(`${API_BASE_URL}/third`),
        axios.get(`${API_BASE_URL}/sabitlenmis`),
      ]);

      // console.log("Slides Response:", slidesResponse.data);
      // console.log("Banner Right Response:", bannerRightResponse.data);
      // console.log("Down Links Response:", downLinksResponse.data);
      // console.log("Third Response:", thirdResponse.data);
      // console.log("Pinned News Response:", pinnedNewsResponse.data);

      const pinnedNews = pinnedNewsResponse.data;

      
      const regularSlides = slidesResponse.data
        .filter(
          (item) =>
            !item.isDraft &&
            !pinnedNews.some(pinnedNews => pinnedNews._id === item._id)
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 20);


      
      const combinedSlides = Array(20).fill(null);

     
      pinnedNews.forEach((news) => {
        if (news.sabitlenmisSlidePosition !== null && news.sabitlenmisSlidePosition >= 1 && news.sabitlenmisSlidePosition <= 20) {
          combinedSlides[news.sabitlenmisSlidePosition - 1] = news;
        }
      });

      
      let regularNewsIndex = 0;
      for (let i = 0; i < 20; i++) {
        if (combinedSlides[i] === null && regularNewsIndex < regularSlides.length) {
          combinedSlides[i] = regularSlides[regularNewsIndex];
          regularNewsIndex++;
        }
      }

      // console.log(slidesResponse.data);


      
      setSlidesData(combinedSlides.filter((slide) => slide !== null));

      const filteredBannerRight = bannerRightResponse.data
        .filter((item) => !item.isDraft)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 1);

      const filteredDownLinks = downLinksResponse.data
        .filter((item) => !item.isDraft)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 6);

      const thirdItem = thirdResponse.data
        .filter((item) => !item.isDraft)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 1)[0];

      let secondItem;
      if (!thirdItem) {
        secondItem = bannerRightResponse.data
          .filter((item) => !item.isDraft)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(1, 2)[0];
      }

      setBannerRightData(
        filteredBannerRight.concat(
          thirdItem ? [thirdItem] : secondItem ? [secondItem] : []
        )
      );

      setDownLinksData(filteredDownLinks);
      setLoading(false);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
      setError(`Veriler alınırken bir hata oluştu: ${error.message}`);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();

    const autoPlayInterval = setInterval(nextSlide, 5000);
    return () => clearInterval(autoPlayInterval);
  }, [fetchData, nextSlide]);

  const slideElements = useMemo(() => {
    return slidesData.map((slide, index) => (
      <div
        className={`slide ${index === currentSlide ? "active" : direction === "forward" && index === (currentSlide - 1 + slidesData.length) % slidesData.length ? "prev" : "next"}`}

        key={slide._id}
        style={{
          backgroundImage: `url(${slide.image})`,
          position: "absolute",
          left: `${index * 100}%`,
          transition: "transform 0.5s ease-in-out",
          transform: `translateX(-${currentSlide * 100}%)`,
          width: "100%",
          height: "100%",
        }}
        onClick={() => handleSlideClick(slide._id, slide.slug)}
      >
        <img
          src={slide.image}
          alt={``}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <div
          className="slide-title"
          style={{
            display:
              slide.isFlashNewsTag ||
                slide.isBreakingNewsTag ||
                slide.isExclusiveNewsTag ||
                slide.isAttentionNewsTag
                ? "block"
                : "none",
            // position: "absolute",
            // top: "10px",
            // right: "10px",
          }}
        >
          <h3
            className="slideSondk"
            style={{
              color: "white",
              padding: "5px 10px",

              fontWeight: "bold",
            }}
          >
            {slide.isFlashNewsTag && "Flaş Haber"}
            {slide.isBreakingNewsTag && "Son Dakika"}
            {slide.isExclusiveNewsTag && "Özel Haber"}
            {slide.isAttentionNewsTag && "Bu Habere Dikkat"}
          </h3>
        </div>
        <div
          className="slide-content"
          style={{
            // position: "absolute",
            // bottom: "0",
            // left: "0",
            // right: "0",
            // background:
            //   "linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0))",
            // padding: "20px 10px 10px",
          }}
        >
          <span
            className="text-left top-left-carousel-text"
            style={{
              color: "white",
              textShadow: "1px 1px 2px rgba(0,0,0,0.8)",

            }}
          >
            {slide.title}
          </span>
        </div>
      </div>
    ));
  }, [slidesData, currentSlide, handleSlideClick]);

  if (loading) return
  //  <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="hp">
      <div className="container w-full h-full">
        <LiveStream />
        <div className="hp_banner_box">
          <div className="hp_banner_left">
            <div className="slider">
              <div className="slider-container cursor-pointer">
                {slideElements}
                <button className="prev-button" onClick={prevSlide}>
                  {/* &#10094; */}
                </button>
                <button className="next-button" onClick={nextSlide}>
                  {/* &#10095; */}
                </button>


              </div>

              <div className="top-carousel-buttons-container">
                {slidesData.map((slide, index) => (
                  <button
                    key={slide._id}
                    className={`page-button ${index === currentSlide ? "active-button" : ""}`}
                    onMouseEnter={() => setCurrentSlide(index)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <div className="numbers">
                {slidesData.map((slide, index) => (
                  <span
                    key={slide._id}
                    className={
                      index === currentSlide ? "number active-number" : "number"
                    }
                    onClick={() => setCurrentSlide(index)}
                  >
                    {""}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="hp_banner_right">
            <div className="newsTitle">
              <div className="newsTop">
                {bannerRightData.map((news) => (
                  <div className="imgBox" key={news._id}>
                    <a
                      href={`/${news.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNewsClick(news._id, news.slug);
                      }}
                    >
                      <div className="overlayTwo">
                        <div className="overlay ">
                          {/* <div
                            className="category-badge"
                            style={{

                            }}
                          >
                            {news.categories.length > 0
                              ? news.categories[0]
                              : ""}
                          </div> */}
                        </div>
                        <p className="text-left ">{news.title}</p>
                      </div>
                      <img
                        src={news.image}
                        alt={news.title}
                        className="image"
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="downLinks">
          <div className="row w-100">
            {downLinksData.map((news) => (
              <div className="col-4" key={news._id}>
                <a
                  href={`/${news.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNewsClick(news._id, news.slug);
                  }}
                >
                  <div className="imgBox">
                    <div className="overlayTwo">
                      <div className={`overlay color-${news.categoryColor}`}>
                        {news.category}
                      </div>
                      <p className="text-left">{news.title}</p>
                    </div>
                    <img src={news.image} alt={news.title} className="image" />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HpBannerBox;
