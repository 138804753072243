import React, { useState, useEffect } from "react";
import "./sdcards.css";
import axios from "axios";

const EXCLUSIVE_NEWS_URL = "https://api.ekonomimanset.com/category/ozel-haber?limit=5";
const API_BASE_URL = "https://api.ekonomimanset.com";

const Card = () => {
  const [exclusiveNews, setExclusiveNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(EXCLUSIVE_NEWS_URL);
        const data = await response.json();

        // console.log("Fetched news ozel haber:", data); 

        const exclusive = data
          .filter((news) => news.isDraft === false)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        setExclusiveNews(exclusive);
        setLoading(false);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleMainCardClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      window.location.href = `/${slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      window.location.href = `/${slug}`;
    }
  };

  const handleSubCardClick = async (newsId, slug, event) => {
    event.stopPropagation(); 
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      window.location.href = `/${slug}`;
    } catch (error) {
      console.error("Alt kart görüntülenme sayısı artırılırken hata oluştu:", error);
      window.location.href = `/${slug}`;
    }
  };

  const currentNews = exclusiveNews[currentIndex];

  return (
    <div
      id="sdcrs"
      className="main-card"
      onClick={(e) => handleMainCardClick(currentNews._id, currentNews.slug, e)}
    >
      <div className="image-container cursor-pointer">
        {loading && <div className="loading">Yükleniyor...</div>}
        <img
          src={currentNews?.image}
          alt="Main"
          onLoad={handleImageLoad}
          style={{ display: loading ? "none" : "block" }}
        />
        <div className="category">
          <button className="category-btn">Özel Haber</button>
        </div>
        <h2>{currentNews?.title}</h2>
      </div>
      <div className="sub-cards">
        {exclusiveNews.map((news, index) => (
          <div
            key={index}
            className={`sub-card ${index === currentIndex ? "active" : ""} cursor-pointer`}
            onClick={(e) => handleSubCardClick(news._id, news.slug, e)}
          >
            <img src={news.image} alt="Sub" />
            <div className="sub-card-text">
              <h3 style={{ textAlign: "left" }}>{news.title}</h3>
              <p style={{ textAlign: "left", fontSize: 11 }}>
                {/* {news.updateDate} / {news.commentCount} Yorum */}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
