import React, { useState, useEffect } from "react";
import "./sliderCard.css";
import Sdcards from "../sliderDownCards/sdcards";
import axios from "axios";

const KRIPTO_NEWS_URL = "https://api.ekonomimanset.com/category/kripto-para";
const DUNYA_NEWS_URL = "https://api.ekonomimanset.com/category/dunya?limit=10";
const API_BASE_URL = "https://api.ekonomimanset.com";

const SliderCard = () => {
  const [mainNews, setMainNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [fade, setFade] = useState(true);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(mainNews.length / itemsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
       

        const dunyaResponse = await fetch(DUNYA_NEWS_URL);
        const dunyaData = await dunyaResponse.json();

        const combinedNews = [...dunyaData].filter(
          (news) => !news.isDraft
        );

        // console.log("Fetched news:", dunyaData); 

        setMainNews(combinedNews.slice(0, 10));
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };

    fetchData();
  }, []);

  const handlePrev = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage > 0 ? prevPage - 1 : totalPages - 1
      );
      setFade(true);
    }, 500);
  };

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage < totalPages - 1 ? prevPage + 1 : 0
      );
      setFade(true);
    }, 500);
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      // Görüntülenme sayısını artır
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

      // Haberi aynı sekmede aç
      window.location.href = `/${slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      // Hata olsa bile haberi aynı sekmede açmaya devam et
      window.location.href = `/${slug}`;
    }
  };

  const startIndex = currentPage * itemsPerPage;
  const currentMainNews = mainNews.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="flex flex-row slider-cards-content">
      
      <div className="slider-card ms-3">
        <div className="slider-main-card">
          {mainNews[0] ? (
            <div
              onClick={(e) =>
                handleNewsClick(mainNews[0]._id, mainNews[0].slug, e)
              }
              className="main-card-link cursor-pointer"
            >
              <img
                src={mainNews[0].image}
                alt="Main"
                className="main-card-image"
              />
              <h2 className="main-card-text">{mainNews[0].title}</h2>
            </div>
          ) : (
            <div>Yükleniyor...</div>
          )}
          <button className="category-slider-btn">Dünya</button>
        </div>
        <div className={`sub-cards ${fade ? "fade-in" : "fade-out"}`}>
          {currentMainNews.length > 0 ? (
            currentMainNews.map((card, index) => (
              <div
                key={index}
                onClick={(e) => handleNewsClick(card._id, card.slug, e)}
                className="sub-card-link cursor-pointer"
              >
                <div className="sub-card">
                  <img src={card.image} alt="Sub" className="sub-card-image" />
                  <div className="sub-card-text">{card.title}</div>
                </div>
              </div>
            ))
          ) : (
            <div>Yükleniyor...</div>
          )}
        </div>
        <div className="navigation">
          <span className="nav-button-left" onClick={handlePrev}>
            <span class="material-symbols-outlined">
chevron_left
</span>
          </span>
          <span className="nav-button-right" onClick={handleNext}>
            <span class="material-symbols-outlined">
chevron_right
</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
