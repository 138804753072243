import React, { useEffect, useState, useRef } from "react";
import "./adsmid.css";

const AdsMidBottom = () => {
  const [ads, setAds] = useState([]);
  const [secondAds, setSecondAds] = useState([]);
  const adRef = useRef(null);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch("https://api.ekonomimanset.com/api/ads");
        const data = await response.json();

        const filteredAds = data.filter(
          (ad) =>
            (ad.title.includes("Sıcak Haberler ÜSTÜ BANNER") && ad.isActive === true)
        );

        const secondFilteredAds = data.filter(
          (ad) =>
            (ad.title.includes("Trendsler ÜSTÜ KARE Banner") && ad.isActive === true)
        );

        setAds(filteredAds);
        setSecondAds(secondFilteredAds);

        // Increment hit count for each ad
        for (const ad of filteredAds) {
          await fetch(`https://api.ekonomimanset.com/api/ads/${ad._id}/hit`, {
            method: 'POST',
          });
        }
        for (const ad of secondFilteredAds) {
          await fetch(`https://api.ekonomimanset.com/api/ads/${ad._id}/hit`, {
            method: 'POST',
          });
        }
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth <= 991);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (adRef.current) {
      adRef.current.innerHTML = ''; // Clear previous content

      ads.forEach((ad) => {
        const adContainer = document.createElement('div');
        adContainer.className = 'ad-item uzunbanner';

        if (ad.imageURL.includes('doubleclick.net') || ad.imageURL.includes('sadbundle')) {
          const iframe = document.createElement('iframe');
          iframe.src = ad.imageURL;
          iframe.width = '1000';
          iframe.height = '300';
          iframe.frameBorder = '0';
          iframe.scrolling = 'no';
          iframe.style = 'border: 0px; vertical-align: bottom;';
          if (ad.imageURL.includes('sadbundle')) {
            iframe.allowFullscreen = true;
          }
          adContainer.appendChild(iframe);
        } else {
          const link = document.createElement('a');
          link.href = ad.link;
          link.target = "_blank";
          link.rel = "noopener noreferrer";

          const img = document.createElement('img');
          img.src = ad.imageURL;
          img.alt = '';
          img.style = 'width: 1000px; height: 300px; object-fit: cover;';

          link.appendChild(img);
          adContainer.appendChild(link);
        }

        adRef.current.appendChild(adContainer);
      });

      if (isWideScreen) {
        secondAds.forEach((ad) => {
          const adContainer = document.createElement('div');
          adContainer.className = 'ad-item reklam1';

          const link = document.createElement('a');
          link.href = ad.link;
          link.target = "_blank";
          link.rel = "noopener noreferrer";

          const img = document.createElement('img');
          img.src = ad.imageURL;
          img.alt = '';

          link.appendChild(img);
          adContainer.appendChild(link);

          adRef.current.appendChild(adContainer);
        });
      }
    }
  }, [ads, secondAds, isWideScreen]);

  return (
    <div className="mid-bottom-ads-container" ref={adRef}>
      {/* Ad content will be dynamically inserted here */}
    </div>
  );
};

export default AdsMidBottom;
