import React, { useEffect, useState } from "react";
import "./kunyePage.css";
import deneme from "./../../assets/mini-logo.png";
import {
  FaEnvelope,
  FaUser,
  FaMapMarker,
  FaPhone,
  FaMobile,
} from "react-icons/fa";

const KunyePage = () => {
  const [kunyeList, setKunyeList] = useState([]);

  useEffect(() => {
    const fetchKunyeData = async () => {
      try {
        const response = await fetch("https://api.ekonomimanset.com/api/kunye"); // Replace with your API endpoint
        const data = await response.json();
        setKunyeList(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchKunyeData();
  }, []);

  return (
    <div className="kny">
      <div className="container member d-flex flex-column">
        <h4 className="knyTitle h4">KÜNYE</h4>
        <div className="row w-100 h-100">

        {kunyeList.map((item) => (
  <div key={item._id} className="col-1 containerBox mb-4 p-0">
    <div className="row">
      <div className="col-6 w-100">
        <div className="row p-1">
          <div className="col-5">
            <img
              src={deneme}
              className="img-fluid w-30 max-h-40 p-3 mx-auto mt-2"
              alt=""
            />
          </div>
          <div className="col-6 contentBox">
            <h6 className="h6 kunyeTitle flex justify-content-center">
              {item.title}
            </h6>

            
            {item.username.split(",").map((name, index) => (
              <div key={index} className="kunyeContent text-left">
                <p className="kunyeName flex">
                  <FaUser className="flex mr-6 mt-1 icon" />
                  <span>{name.trim()}</span>
                </p>

                
                {Array.isArray(item.email) &&
                  item.email
                    .filter((_, i) => i === index) 
                    .map((email, i) => (
                      <a key={i} href={`mailto:${email.trim()}`} className="flex mb-3">
                        <FaEnvelope className="flex mr-6 mt-1  icon" />
                        {email.trim()}
                      </a>
                    ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
))}
          
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-5 h-full d-flex justify-content-center mapBox mt-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.783574349977!2d29.028493511500518!3d40.98621972066344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab927e9d8c52b%3A0xa510d33cae6c139e!2sEri%C5%9Fim%20Medya!5e0!3m2!1str!2str!4v1720163184140!5m2!1str!2str"
                  className="img-fluid"
                  width="250"
                  height="150"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-7 contentBox">
                <h3 className="h6">ADRES</h3>
                <p className="kunyeContent text-left">
                  <span className="flex">
                    <FaMapMarker className="flex mr-6 mapIcon" />
                    ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK ANONİM
                    ŞİRKETİ Osmanağa Mah. Hasırcıbaşı Cad. Hasırcıbaşı Apt.
                    No:15/3 Kadıköy/İstanbul
                  </span>
                  <span className="flex">
                    <FaPhone className="flex mt-1 mr-6" />
                    (+90) 216 550 10 61/62
                  </span>
                  <span className="flex">
                    <FaMobile className="flex mt-1 mr-6" />
                    (+90) 532 462 76/87
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KunyePage;
