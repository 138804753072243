import "./App.css";
import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Kunye from "./pages/kunye/KunyePage";
import AnimatedText from "../src/components/animatedText/AnimatedText";
import AdsLeft from "../src/components/ads/AdsLeft";
import AdsRight from "../src/components/ads/AdsRights";
import Footer from "./components/footer/Footer";
import TopButton from "./components/topButton/TopButton";
import ContentPage from "./pages/content/ContentPage";
import ContactPage from "./pages/contact/ContactPage";
import About from "./pages/about/About";
import PuanDurumu from "./components/puanDurumu/puanDurumu";
import ProfilePage from "./pages/profile/ProfilePage";
import PrayerTime from "./components/namaz/PrayerTime";
import Altin from "./components/altin/altin";
import Kur from "./components/kurDegisim/kur";
import TrafficInfo from "./components/traffic/TrafficInfo";
import PharmacyPage from "./components/pharmacy/PharmacyPage";
import CryptoCategory from "./pages/category/CryptoCategory";
import DailyCategory from "./pages/category/DailyCategory";
import SpecialCategory from "./pages/category/SpecialPage";
import EconomyCategory from "./pages/category/EconomyPage";
import FinanceCategory from "./pages/category/FinancePage";
import TechnologyCategory from "./pages/category/TechnologyPage";
import WorldCategory from "./pages/category/WorldPage";
import Kripto from "./components/kripto/kripto";
import Hisse from "./components/hisse/hisse";
import SearchPage from "./pages/search/SearchPage";
import LiveTv from "./components/liveTv/LiveTv";
import Parite from "./components/parite/parite";
import Weather from "./components/weatherPage/weather";
import AdsMobile from "./components/ads/AdsMobile";
import AdsTablet from "./components/ads/adsTablet.jsx";
import AdsDownPopup from "./components/ads/adsDownPopup";
import CategoryPage from "./components/sideMenu/CategoryPage";
import MehmetAvciPage from "./pages/category/mehmetAvciCategory";
import AzizHatmanPage from "./pages/category/azizHatmanCategory";
import OrhanErturkPage from "./pages/category/orhanErturkCategory.jsx";
import MuzafferDemirPage from "./pages/category/muzafferDemirCategory.jsx";
import AlbumCategory from "./pages/category/AlbumCategory";
import AlbumContentPage1 from "./pages/photoAlbum/AlbumContentPage1";
import AlbumContentPage2 from "./pages/photoAlbum/AlbumContentPage2";
import AlbumContentPage3 from "./pages/photoAlbum/AlbumContentPage3";
import AlbumContentPage4 from "./pages/photoAlbum/AlbumContentPage4";
import AlbumContentPage5 from "./pages/photoAlbum/AlbumContentPage5";
import PrivacyPolicy from "./pages/policy/PrivacyPolicy.jsx";
import ClarificationText from "./pages/policy/ClarificationText.jsx";
import ApprovedPage from "./pages/approval/ApprovedPage.jsx";
import DeclinedPage from "./pages/approval/DeclinedPage.jsx";
import TagsPage from "./pages/category/TagsPage.jsx";
import PersonalData from "./pages/policy/PersonalData.jsx";



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div className="App">
      
      <Router>
      <ScrollToTop />
        <AnimatedText />
        <Navbar />
        <AdsMobile />
        <AdsTablet/>
        <AdsLeft />
        <AdsRight />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<ContentPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/:category" element={<CategoryPage />} />{" "}
          <Route path="/about" element={<About />} />
          <Route path="/kripto-para" element={<CryptoCategory />} />
          <Route path="/gundem" element={<DailyCategory />} />
          <Route path="/reel-ekonomi" element={<EconomyCategory />} />
          <Route path="/ozel-haber" element={<SpecialCategory />} />
          <Route path="/finans" element={<FinanceCategory />} />
          <Route path="/teknoloji" element={<TechnologyCategory />} />
          <Route path="/dunya" element={<WorldCategory />} />
          <Route path="/Kunye" element={<Kunye />} />
          <Route path="/puanDurumu" element={<PuanDurumu />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/namaz" element={<PrayerTime />} />
          <Route path="/Altin" element={<Altin />} />
          <Route path="/Kur" element={<Kur />} />
          <Route path="/traffic" element={<TrafficInfo />} />
          <Route path="/pharmacy" element={<PharmacyPage />} />
          <Route path="/kripto" element={<Kripto />} />
          <Route path="/hisse" element={<Hisse />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/canli-tv" element={<LiveTv />} />
          <Route path="/parite" element={<Parite />} />
          <Route path="/weather" element={<Weather />} />
          <Route path="/mehmet-avci" element={<MehmetAvciPage />} />
          <Route path="/muzaffer-demir" element={<MuzafferDemirPage />} />
          <Route path="/orhan-erturk" element={<OrhanErturkPage />} />
          <Route path="/aziz-hatman" element={<AzizHatmanPage />} />
          <Route path="/foto-galeri" element={<AlbumCategory />} /> 
          <Route path="/photo-album-1" element={<AlbumContentPage1 />} />
          <Route path="/photo-album-2" element={<AlbumContentPage2 />} /> 
          <Route path="/photo-album-3" element={<AlbumContentPage3 />} /> 
          <Route path="/photo-album-4" element={<AlbumContentPage4 />} />
          <Route path="/photo-album-5" element={<AlbumContentPage5 />} />
          <Route path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
          <Route path="/aydinlatma-metni" element={<ClarificationText />} />
          <Route path="/kvkk" element={<PersonalData />} />
          <Route path="/onayliyorum" element={<ApprovedPage />} />
          <Route path="/onaylamiyorum" element={<DeclinedPage />} />
          <Route path="/tags/:tag" element={<TagsPage />} />
        </Routes>
        <AdsDownPopup />
        <TopButton />
        <Footer />
      </Router>
      
    </div>
  );
}

export default App;
