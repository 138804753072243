import React, { useState } from "react";
import "./contactPage.css";
import ads2 from "./gazze.jpg";
import { FaInstagram, FaLinkedin, FaXTwitter, FaYoutube } from "react-icons/fa6";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    adSoyad: "",
    ePosta: "",
    konu: "", 
    baslik: "",
    mesaj: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); 
  
    
    const formDataWithDefaultTitle = {
      ...formData,
      baslik: formData.baslik || "[Baslik]", 
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataWithDefaultTitle),
      });
  
      if (!response.ok) {
        throw new Error("Form gönderimi başarısız");
      }
  
      setShowPopup(true);
      setFormData({
        adSoyad: "",
        ePosta: "",
        konu: "",
        baslik: "",
        mesaj: "",
      });
  
      setTimeout(() => setShowPopup(false), 3000);
    } catch (error) {
      console.error("Form gönderimi hatası:", error);
      setErrorMessage("Form gönderimi başarısız. Lütfen tekrar deneyin.");
    }
  };
  

  return (
    <div className="contact-container pb-[150px]">
      <div className="contact-top-container md:grid grid-cols-11 md:px-60 ">
        <div className="contact-top-left-container col-span-7 md:pr-[30px] ">
          <h1 className="contact-page-title1">Bize Ulaşın</h1>
          <h3 className="font-semibold text-gray-700 text-base my-4">
            Öneri, eleştiri ya da reklam teklifleriniz için bizimle iletişime
            geçebilirsiniz.
          </h3>

          <form onSubmit={handleSubmit}>
            <div className="md:grid grid-cols-2 gap-5">
              <div>
                <h5 className="contact-page-title2">Ad Soyad *</h5>
                <input
                  className="contact-page-input"
                  type="text"
                  name="adSoyad"
                  value={formData.adSoyad}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <h5 className="contact-page-title2">E-Posta *</h5>
                <input
                  className="contact-page-input"
                  type="email"
                  name="ePosta"
                  value={formData.ePosta}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="md:grid grid-cols-2 gap-5">
              <div>
                <h5 className="contact-page-title2">Konu</h5>
                <select
                  className="contact-page-input"
                  name="konu"
                  value={formData.konu}
                  onChange={handleInputChange}
                  required
                >
                  <option value="volvo">Konu Seçiniz</option>
                  <option value="Sponsorluk">Sponsorluk</option>
                  <option value="Editörlük">Editörlük</option>
                  <option value="Öneri / Şikayet">Öneri / Şikayet</option>
                  <option value="Diğer">Diğer</option>
                </select>
              </div>

              <div>
                <h5 className="contact-page-title2">Başlık*</h5>
                <input
                  className="contact-page-input"
                  type="text"
                  name="baslik"
                  value={formData.baslik}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <h5 className="contact-page-title2">Mesajınız *</h5>
              <textarea
                className="contact-page-input-message"
                name="mesaj"
                value={formData.mesaj}
                onChange={handleInputChange}
                rows="10"
                placeholder="Mesajınızı buraya yazabilirsiniz"
                required
              ></textarea>
            </div>

            <p className="text-gray-400 text-sm mb-4">
              * işaretli alanların doldurulması zorunludur.
            </p>

            <input
              className="contact-page-submit-button"
              type="submit"
              value="Gönder"
            ></input>
          </form>

          {showPopup && (
            <div className="popup">
              <p>Mesajınız başarıyla iletilmiştir.</p>
            </div>
          )}

          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}

          
        </div>

        <div className="contact-top-right-container col-span-4 md:pl-[30px]">
          <h1 className="contact-page-title1">İletişim Bilgileri</h1>

          <div className="leading-9 break-normal">
            <div className="grid grid-cols-9 gap-x-2 text-left text-gray-700">
              <div>
              <span class="material-symbols-outlined pt-1">
call
</span>
              </div>
              <div className="col-span-8 text-left text-gray-600">
                <span>+90 216 550 10 61 / 62</span>
              </div>
            </div>

            <div className="grid grid-cols-9 gap-x-2 text-left text-gray-700">
              <div>
              <span class="material-symbols-outlined pt-1">
mail
</span>
              </div>
              <div className="col-span-8 text-left text-gray-600">
                <span>bbekar@akilliyasamdergisi.com</span>
              </div>
            </div>

            <div className="grid grid-cols-9 gap-x-2 text-left text-gray-700">
              <div>
              <span class="material-symbols-outlined pt-1">
location_on
</span>
              </div>
              <div className="col-span-8 text-left text-gray-600">
                Osmanağa Mah. Hasırcıbaşı Cad. Hasırcıbaşı Apt. No:15/3
                Kadıköy/İstanbul
              </div>
            </div>
          </div>

          <h1 className="contact-page-title1 mt-4">Bizi Takip Edin</h1>

          <div className="flex text-gray-700 text-4xl justify-evenly center mb-6">
            <div>
              <a
                href="https://twitter.com/manset_ekonomi"
                className="hover:text-[#50ABF1] "
                target="_blank"
                rel="noreferrer"
              >
                <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                  <FaXTwitter />
                </div>
              </a>
            </div>

            <div>
              <a
                href="https://www.instagram.com/ekonomimanset/"
                className="hover:text-[#FE1D80]"
                target="_blank"
                rel="noreferrer"
              >
                <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                  <FaInstagram />
                </div>
              </a>
            </div>

            <div>
              <a
                href="https://www.linkedin.com/company/ekonomi-manset/"
                className="hover:text-[#0077B5]"
                target="_blank"
                rel="noreferrer"
              >
                <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                  <FaLinkedin />
                </div>
              </a>
            </div>

            <div>
              <a
                href="https://www.youtube.com/@ekonomimanset"
                className="hover:text-[#FF0000]"
                target="_blank"
                rel="noreferrer"
              >
                <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                  <FaYoutube />
                </div>
              </a>
            </div>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d24094.26630046402!2d29.031091000000004!3d40.986226!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDU5JzEwLjQiTiAyOcKwMDEnNTEuOSJF!5e0!3m2!1str!2sus!4v1720001826226!5m2!1str!2sus"
            width="100%"
            height="300"
            allowfullscreen=""
            className="mt-4"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          {/* <div>
            <img
              src={ads2}
              alt=""
              className=" w-full object-cover"
            />
            <img className="mt-4 w-full"
            style={{ margin: "0 auto" }}
            src="https://www.psmmag.com/wp-content/uploads/2024/01/IMG_3460.jpeg"
            alt=""
          />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
