import React, { useState, useEffect } from "react";
import axios from "axios";

const TRENDS_API_URL = "https://api.ekonomimanset.com/trends?limit=5";
const API_BASE_URL = "https://api.ekonomimanset.com";

const MenuPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(TRENDS_API_URL);
        const data = response.data;
  
        // console.log(data);
  
        const sortedPosts = data
          .filter((post) => !post.isDraft)
          .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate));
  
        const recentPosts = sortedPosts.slice(0, 5);

        setPosts(recentPosts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Veriler alınırken bir hata oluştu.");
        setLoading(false);
      }
    };
  
    fetchPosts();
  }, []);
  

  const handlePostClick = async (post, event) => {
    event.preventDefault();
    try {
      
      await axios.put(`${API_BASE_URL}/${post._id}/increment-view`);
  
      window.location.href = `/${post.slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      
      window.location.href = `/${post.slug}`;
    }
  };

  if (loading) return 
  // <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mt-4 flex flex-col gap-8">
      <div className="p-3">
        {posts.map((post) => (
          <div
            key={post._id}
            className="cursor-pointer"
            onClick={(e) => handlePostClick(post, e)}
          >
            <div className="flex flex-col gap-1 text-left mt-2">
              <div className="flex flex-row">
                <span className="px-2 py-0.5 rounded-lg text-xs text-white w-max bg-red-400">
                  {post.categories.join(", ")}
                </span>
                <div className="text-xs ms-2">
                  {/* <span className="text-gray-600">{post.author}</span> */}
                  <span className="text-gray-600">
                    {" "}
                    - {new Date(post.updateDate).toLocaleDateString()}
                  </span>
                </div>
              </div>
              <h3 className="text-base font-medium text-gray-800">
                {post.title}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuPosts;
