import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./adsCenterPopup.css";

const AdsCenterPopup = () => {
  const [show, setShow] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 2000); // Show pop-up after 2 seconds
    return () => clearTimeout(timer); // Cleanup
  }, []);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.ekonomimanset.com/api/ads");
        const ads = response.data;

        const popupAd = ads.find(
          (ad) => ad.title === "POPUP" && ad.isActive === true
        );

        if (popupAd) {
          setPopupData(popupAd);
          await axios.post(`https://api.ekonomimanset.com/api/ads/${popupAd._id}/hit`);
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("touchstart", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [show]);

  const handleClose = () => {
    setShow(false);
    document.body.style.overflow = 'auto'; // Enable scrolling when modal is closed
  };

  if (!popupData || !popupData.isActive) {
    return null;
  }

  const isIframe = popupData.imageURL.includes("doubleclick.net") || popupData.imageURL.includes("sadbundle");

  if (show) {
    document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
  }

  return (
    <div className={`adsPopupCenter ${show ? 'active' : ''}`}>
      {show && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" ref={modalRef}>
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="close-button"
                  onClick={handleClose}
                >
                  &times;
                </button>
                <a href={popupData.link} target="_blank" rel="noopener noreferrer">
                  {isIframe ? (
                    <iframe
                      src={popupData.imageURL}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      scrolling="no"
                      style={{ border: 0, verticalAlign: "bottom" }}
                      allowFullScreen={popupData.imageURL.includes("sadbundle")}
                    />
                  ) : (
                    <img
                      src={popupData.imageURL}
                      alt="Popup"
                      className="img-fluid"
                    />
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdsCenterPopup;