import React, { useState, useEffect } from "react";
import "./adsmid.css";
import "./x.css";

const AdContainer = ({ ad, handleAdClick }) => {
  return (
    <div className="adsmidtop-ad-wrapper">
      <a href={ad.link} target="_blank" rel="noopener noreferrer" onClick={() => handleAdClick(ad._id)}>
        <div className="adsmidtop-ad-container">
          <div className="adsmidtop-ad-content">
            {ad.imageURL.includes('doubleclick.net') || ad.imageURL.includes('sadbundle') ? (
              <iframe
                src={ad.imageURL}
                frameBorder="0"
                scrolling="no"
                allowFullScreen={ad.imageURL.includes('sadbundle')}
              />
            ) : (
              <img src={ad.imageURL} alt={ad.title} />
            )}
          </div>
        </div>
      </a>
    </div>
  );
};

const AdsMidTop = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch("https://api.ekonomimanset.com/api/ads");
        const data = await response.json();

        const filteredAds = data.filter(
          (ad) =>
            (ad.title.includes("Manşet Altı KARE Banner - SOL") ||
             ad.title.includes("Manşet Altı KARE Banner - ORTA") ||
             ad.title.includes("Manşet Altı KARE Banner - SAĞ")) &&
            ad.isActive === true
        );

        setAds(filteredAds);
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  const handleAdClick = async (adId) => {
    try {
      await fetch(`https://api.ekonomimanset.com/api/ads/${adId}/hit`, {
        method: "POST",
      });
    } catch (error) {
      console.error("Error incrementing hit count:", error);
    }
  };

  return (
    <div className="adsmidtop-container">
      {ads.map((ad, index) => (
        <AdContainer key={index} ad={ad} handleAdClick={handleAdClick} />
      ))}
    </div>
  );
};

export default AdsMidTop;